<template>
  <div class="page-report">
    <container>
      <a-form-model
        ref="filterForm"
        class="filters-form"
        :model="form"
        @submit.prevent="onSubmit"
      >
        <a-row class="filters-row" :gutter="[15, 15]">
          <a-col
            class="filters-col"
            :md="{ span: 4 }"
            :sm="{ span: 12 }"
            :xs="{ span: 24 }"
          >
            <a-range-picker
              size="large"
              format="DD/MM/YYYY"
              style="width: 100%"
              @change="onChangeDate"
            ></a-range-picker>
          </a-col>

          <a-col
            class="filters-col"
            :lg="{ span: 4 }"
            :md="{ span: 8 }"
            :sm="{ span: 12 }"
            :xs="{ span: 24 }"
          >
            <a-form-model-item class="mb-0" prop="direction">
              <a-select
                v-model="form.directions"
                mode="multiple"
                size="large"
                placeholder="Выберите направление"
              >
                <a-select-option
                  v-for="option in directions"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col
            class="filters-col"
            :lg="{ span: 4 }"
            :md="{ span: 8 }"
            :sm="{ span: 12 }"
            :xs="{ span: 24 }"
          >
            <a-form-model-item class="mb-0" prop="regions">
              <a-select
                v-model="form.regions"
                mode="multiple"
                size="large"
                placeholder="Выберите область"
              >
                <a-select-option
                  v-for="option in regions"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col
            class="filters-col"
            :lg="{ span: 4 }"
            :md="{ span: 8 }"
            :sm="{ span: 12 }"
            :xs="{ span: 24 }"
          >
            <a-form-model-item class="mb-0" prop="manager">
              <a-select
                v-model="form.managers"
                mode="multiple"
                size="large"
                placeholder="Выберите менеджера"
              >
                <a-select-option
                  v-for="option in managers"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ `${option.name} ${option.lastName}` }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col
            class="filters-col"
            :lg="{ span: 4 }"
            :md="{ span: 8 }"
            :sm="{ span: 12 }"
            :xs="{ span: 24 }"
          >
            <a-form-model-item class="mb-0" prop="statuses">
              <a-select
                v-model="form.statuses"
                mode="multiple"
                size="large"
                placeholder="Выберите статус"
              >
                <a-select-option
                  v-for="option in statuses"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col
            class="filters-col d-flex align-items-center"
            :lg="{ span: 4 }"
            :md="{ span: 8 }"
            :sm="{ span: 12 }"
            :xs="{ span: 24 }"
          >
            <a-button
              type="primary"
              size="large"
              :loading="dataLoading"
              @click="getData"
            >
              Показать
            </a-button>

            <a
              v-if="downloadLink"
              :href="downloadLink"
              download="отчет.xlsx"
              class="ml-15"
            >
              <a-button type="primary" size="large" icon="download"></a-button>
            </a>
          </a-col>
        </a-row>
      </a-form-model>

      <a-row class="mt-20">
        <a-col :span="24">
          <a-table
            v-if="columns.length && data.length"
            :columns="columns"
            :data-source="data"
            :pagination="{
              defaultPageSize: 40,
              showSizeChanger: true,
            }"
            class="report-table"
          ></a-table>
        </a-col>
      </a-row>
    </container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import apiRequest from '../js/helpers/apiRequest';

import Container from '../components/Container.vue';

export default {
  name: 'Report',

  components: {
    Container,
  },

  data() {
    return {
      form: {
        dateFrom: '',
        dateTo: '',
        regions: [],
        managers: [],
        statuses: [],
        directions: [],
      },

      dataLoading: false,
      downloadLink: null,
      data: [],
      columns: [],
    };
  },

  computed: {
    ...mapState({
      filtersLoad: (state) => state.filters.filtersLoad,
      managers: (state) => state.managers.managers,
      statuses: (state) => state.filters.statuses,
      regions: (state) => state.filters.regions,
      directions: (state) => state.filters.directions,
    }),
  },

  methods: {
    onChangeDate(date, dateString) {
      const { form } = this;

      form.dateFrom = dateString[0];
      form.dateTo = dateString[1];
    },

    async getData() {
      try {
        const body = new FormData();
        const {
          form: { dateFrom, dateTo, directions, regions, managers, statuses },
        } = this;

        if (!dateFrom.length && !dateTo.length && !managers.length) {
          this.$notification.warning({
            message: 'Ошибка',
            description: 'Выберите дату или менеджера',
          });
        } else {
          body.append('date_from', dateFrom);
          body.append('date_to', dateTo);
          regions.forEach((region) => {
            body.append('regions[]', region);
          });
          directions.forEach((direction) => {
            body.append('directions[]', direction);
          });
          managers.forEach((manager) => {
            body.append('users[]', manager);
          });
          statuses.forEach((status) => {
            body.append('statuses[]', status);
          });

          this.dataLoading = true;
          const res = await apiRequest(
            '/object/history/statistic',
            'POST',
            body,
            true
          );
          this.dataLoading = false;

          if (res.histories) {
            this.downloadLink = res.link;
            const data = res.histories.map(
              ({
                id,
                date_at,
                status,
                users,
                object,
                activity,
                direction,
                comment,
              }) => ({
                key: id,
                date: format(new Date(date_at), 'dd/MM/yyyy'),
                dateForFilter: format(new Date(date_at), 'yyyy/MM/dd'),
                status: this.statuses.find((item) => item.id == status).name,
                manager: users[0]
                  ? `${users[0].first_name} ${users[0].last_name}`
                  : '',
                object: object.name || '',
                action: activity || '',
                region: this.regions.find(
                  (region) => region.id === Number(object.region)
                ).name,
                direction: direction
                  ? this.directions.find((item) => item.id == direction).name
                  : '',
                comment,
              })
            );

            let managerFilterOptions = [];
            let regionFilterOptions = [];
            let objectFilterOptions = [];
            let actionFilterOptions = [];
            let directionFilterOptions = [];
            let statusFilterOptions = [];

            data.forEach((item) => {
              const {
                manager,
                object,
                region,
                action,
                direction,
                status,
              } = item;

              if (manager) {
                managerFilterOptions = [manager, ...managerFilterOptions];
              }

              if (region) {
                regionFilterOptions = [region, ...regionFilterOptions];
              }

              if (object) {
                objectFilterOptions = [object, ...objectFilterOptions];
              }

              if (action) {
                actionFilterOptions = [action, ...actionFilterOptions];
              }

              if (direction) {
                directionFilterOptions = [direction, ...directionFilterOptions];
              }

              statusFilterOptions = [status, ...statusFilterOptions];
            });

            this.columns = [
              {
                title: 'Статус',
                dataIndex: 'status',
                filters: Array.from(
                  new Set(statusFilterOptions)
                ).map((value) => ({ text: value, value })),
                onFilter: (value, record) => record.status.indexOf(value) === 0,
                sorter: (a, b) => a.status.length - b.status.length,
              },
              {
                title: 'Дата',
                dataIndex: 'date',
                defaultSortOrder: 'descend',
                sorter: (a, b) =>
                  new Date(a.dateForFilter) - new Date(b.dateForFilter),
              },
              {
                title: 'Менеджер',
                dataIndex: 'manager',
                filters: Array.from(
                  new Set(managerFilterOptions)
                ).map((value) => ({ text: value, value })),
                onFilter: (value, record) =>
                  record.manager.indexOf(value) === 0,
                sorter: (a, b) => a.manager.length - b.manager.length,
              },
              {
                title: 'Объект',
                dataIndex: 'object',
                filters: Array.from(
                  new Set(objectFilterOptions)
                ).map((value) => ({ text: value, value })),
                onFilter: (value, record) => record.object.indexOf(value) === 0,
                sorter: (a, b) => a.object.length - b.object.length,
              },
              {
                title: 'Область',
                dataIndex: 'region',
                filters: Array.from(
                  new Set(regionFilterOptions)
                ).map((value) => ({ text: value, value })),
                onFilter: (value, record) => record.region.indexOf(value) === 0,
                sorter: (a, b) => a.region.length - b.region.length,
              },
              {
                title: 'Действие',
                dataIndex: 'action',
                filters: Array.from(
                  new Set(actionFilterOptions)
                ).map((value) => ({ text: value, value })),
                onFilter: (value, record) => record.action.indexOf(value) === 0,
                sorter: (a, b) => a.action.length - b.action.length,
              },
              {
                title: 'Направление',
                dataIndex: 'direction',
                filters: Array.from(
                  new Set(directionFilterOptions)
                ).map((value) => ({ text: value, value })),
                onFilter: (value, record) =>
                  record.direction.indexOf(value) === 0,
                sorter: (a, b) => a.direction.length - b.direction.length,
              },
              {
                title: 'Комментарий',
                dataIndex: 'comment',
              },
            ];

            this.data = data;
          }
        }
      } catch (error) {
        console.log('getData:', error);
        this.dataLoading = false;
        this.$notification.warning({
          message: 'Ошибка',
          description: 'Что-то пошло не так..',
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>
